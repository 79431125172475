// MarketingRedirect.jsx
import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";

function MarketingRedirect() {
  const { uniqueCode } = useParams();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    // Fire a GA event
    if (window.gtag) {
      window.gtag("event", "MarketingMaterialClick", {
        event_category: "MarketingMaterial",
        event_label: uniqueCode,
        value: 1
      });
    }

    // small delay so GA can record
    const timer = setTimeout(() => {
      setShouldRedirect(true);
    }, 150);

    return () => clearTimeout(timer);
  }, [uniqueCode]);

  if (shouldRedirect) {
    // redirect to homepage or anywhere
    return <Redirect to="/" />;
  }

  return <div>Redirecting...</div>;
}

export default MarketingRedirect;
