import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiPhone, FiMapPin } from "react-icons/fi";
import GoogleMap from "../component/GoogleMaps/GoogleMap"
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";
import axios from 'axios';



function Contact() {
    const [data, setData] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: response } = await axios.get('https://rpsych.sfo3.digitaloceanspaces.com/OfficeList.json');
                setData(response);
            } catch (error) {
                console.error(error.message);
            }
        }

        fetchData();
    }, []);
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Contact' />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--35" data-black-overlay="6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title color-1">Contact Us</h2>
                                <p>Find our Email, Phone #, and Locations below, or send us a message!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}


            {/* Start Contact Top Area  */}
            <div className="rn-contact-top-area ptb--120 bg_color--5">
                <div className="container">
                    <div className="row">
                        {/* Start Single Address  */}
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiPhone />
                                </div>
                                <div className="inner">
                                    <h2 className="title">Phone Number</h2>
                                    <p><strong>Main: </strong><a href="tel:4804718560">480.471.8560</a></p>
                                    <p><strong>Fax: </strong>888.979.8197</p>
                                    <p>Phones are only answered Monday-Friday 8am-5pm</p><br />
                                    {data.map((item, index) => (
                                        item.Name === "Virtual" ?
                                            <div key={index}>
                                            </div>
                                            :
                                            <div key={index}>
                                                <h5 className="title" style={{ borderBottom: "dashed" }}>{item.Name}</h5>
                                                <a href={"tel:" + item.Phone}><p style={{ background: '#F8F9FC', padding: '5px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>{item.Phone}</p></a>
                                                <br />
                                            </div>
                                    ))}
                                </div>
                            </div>
                            <div className="rn-address">
                                <div className="inner">
                                    <h2 className="title">Email Addresses</h2>
                                    {data.map((item, index) => (
                                        item.Name === "Virtual" ?
                                            <div key={index}>
                                            </div>
                                            :
                                            <div key={index}>
                                                <h5 className="title" id={index} style={{ display: "inline" }}>{item.Name}</h5>
                                                <p style={{ background: '#F8F9FC', padding: '5px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}><a style={{ display: "inline" }} href={"mailto:" + item.Email}>{item.Email}</a></p>
                                                <br />
                                            </div>
                                    ))}
                                    <div key="Outreach">
                                        <h5 className="title" id="Outreach" style={{ display: "inline" }}>Partnerships & Outreach</h5>
                                        <p style={{ background: '#F8F9FC', padding: '5px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}><a style={{ display: "inline" }} href={"mailto:outreach@rpsych.com"}>outreach@rpsych.com</a></p>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}

                        {/* Start Single Address  */}
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiMapPin />
                                </div>
                                <div className="inner">
                                    <h2 className="title">Locations</h2>
                                    {data.map((item, index) => (
                                        item.Name === "Virtual" ?
                                            <div key={index}>
                                            </div>
                                            :
                                            <div key={index}>
                                                <h5 className="title" style={{ borderBottom: "dashed" }}>{item.Name}</h5>
                                                <a target="_blank" rel="noopener noreferrer" href={item.DirectionsLink}><p style={{ background: '#F8F9FC', padding: '5px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>{item.Address}<br /> {item.City}, {item.State} {item.Zip}</p></a>
                                                <br />
                                            </div>
                                    ))}
                                </div>
                            </div>
                            <div className="rn-address">
                                <div className="inner">
                                    <h2 className="title">Mailing Address</h2>
                                    <h5 className="title" style={{ borderBottom: "dashed" }}>All Mail Goes To: </h5>
                                    <p>3489 E Baseline Road <br />Gilbert, AZ 85234</p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}

                    </div>
                </div>
            </div>
            {/* End Contact Top Area  */}

            {/* Start Contact Map  */}
            <div className="rn-contact-map-area position-relative">
                <div style={{ height: '650px', width: '100%' }}>
                    <GoogleMap />
                </div>
            </div>
            {/* End Contact Map  */}

            {/* Start Contact Page Area  */}
            <div className="rn-contact-page ptb--80 bg_color--1">
                <ContactTwo />
            </div>
            {/* End Contact Page Area  */}

            {/* Start Discharge Planners Area */}
            <div className="rn-about-area pb--80 bg_color--1">
                <div className="container">
                    <div className="section-title" style={{ textAlign: "center" }}>
                        <h2 className="title">For Discharge Planners</h2>
                        <p>We understand the importance of coordinated care during the discharge process. Our team is committed to working with discharge planners to ensure that patients transitioning from inpatient to outpatient care receive the support they need. Please contact our Discharge Coordinator to discuss how we can assist with your patient's discharge plan: </p>
                        <p>referrals@rpsych.com</p>
                        <p>(480) 870-4181</p>
                    </div>
                </div>
            </div>
            {/* End Discharge Planners Area */}

            {/* Start Brand Area */}
            {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* End Brand Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />

        </React.Fragment>
    )
}

export default Contact